import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';
import Crane from '../assets/images/get.jpg';

function GetQuotation() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [port, setPort] = useState("");
  const [weight, setWeight] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    emailjs.sendForm('service_ro5fjwj', 'template_fnmdfni', form, 'PaUKTiOEglG-mvxtX')
      .then((result) => {
        console.log('Email successfully sent!', result.text);
        alert('Quotation request sent successfully!');
        
        // Clear the form fields after successful submission
        setName("");
        setEmail("");
        setPort("");
        setWeight("");
        setDeliveryAddress("");
        setDescription("");
      }, (error) => {
        console.log('Failed to send email.', error.text);
        alert('Failed to send quotation request. Please try again.');
      });
  };

  return (
    <div>
      <Navbar />
      <section className='bg-[#EDEDED]'>
        <div style={{ backgroundImage: `url(${Crane})` }} className="h-72 bg-cover bg-center bg-no-repeat w-full flex ">
          <div className="h-72 w-full sm:px-12 backdrop-brightness-200 bg-black/70 content-center items-center">
            <div className="sm:w-[90%] p-2 my-auto flex flex-col sm:justify-between">
              <p className="cursor-pointer duration-500 text-lg text-white">Get a Quotation</p>
              <h1 className="text-white font-medium text-5xl">We are always here<br /> at your service.</h1>
            </div>
          </div>
        </div>
        <div className='w-full px-2 sm:px-0 sm:w-[65%] mx-auto py-5 sm:py-14 flex flex-col sm:flex-row gap-x-10 divide-x-[1px] text-[#003260]'>
          <div className='py-2 sm:py-0'>
            <p className='font-thin text-3xl'>Get a Quote</p>
            <h1>We would give you an estimate in no time!</h1>
          </div>
          <form onSubmit={handleSubmit} className='px-5 divide--[1px] text-[#003260]'>
            <div className="space-y-3">
              <div className="border-b border-gray-900/10 py-6 sm:pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Client Information</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-full">
                  <div className="sm:col-span-full">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor="port" className="block text-sm font-medium leading-6 text-gray-900">
                      Port
                    </label>
                    <div className="mt-2">
                      <select
                        id="port"
                        name="port"
                        value={port}
                        onChange={(e) => setPort(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value="">Select a port</option>
                        <option value="Tin Can">Tin Can</option>
                        <option value="Lekki">Lekki</option>
                        <option value="APMT">APMT</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900">
                      Weight
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="weight"
                        id="weight"
                        placeholder='e.g., 1234kg'
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:max-w-xs sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label htmlFor="deliveryAddress" className="block text-sm font-medium leading-6 text-gray-900">
                      Delivery address
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="deliveryAddress"
                        id="deliveryAddress"
                        value={deliveryAddress}
                        onChange={(e) => setDeliveryAddress(e.target.value)}
                        autoComplete="street-address"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    rows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-[#003260] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#003260]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default GetQuotation;
