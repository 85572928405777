import React from 'react'
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <div>
      <section className="bg-[#1C1C1C] w-100vw px-2 sm:px-0">
        <div className="flex flex-col  sm:flex-row sm:w-[90%] justify-between sm:space-x-10 mx-auto  items-center py-16">
          <div className=" flex flex-col gap-4">
          <div className="flex cursor-pointer space-x-2 text-white hover:text-orange-300">
        <div>
          <img className="w-12 h-10 sm:w-20 sm:h-10" src={require('../assets/images/logo.png')} />
        </div>
        <div className="leading-[15px] font-serif font-extrabold  flex flex-col align-middle justify-center">
        <p>OPELOPE OLUWA DIVINE</p>
        <p className="whitespace-nowrap">TRANSPORT ENT</p>
        </div>
      </div>

            <div className=" sm:w-[70%] flex-col flex sm:flex-row text-base sm:gap-6 gap-3 text-[#94a0b0]">
              <h1 className="">
                Lagos Office: No 82, Tomez avenue along ago-okota road, Amuwo Odofin, Lagos State.
              </h1>

            </div>

            <div className="flex flex-col gap-4 text-base text-[#94a0b0]">
              <h1 className="">08168826848</h1>
              <p className="">
                Copyright © 2024 <a href="https://dammymure.vercel.app/">DammyMure.</a>  All rights reserved.
              </p>
            </div>
          </div>

          <div className='flex mx-auto justify-center align-middle content-center'>

          <iframe className='w-96 h-96' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2879.5526350384507!2d3.3079129925593183!3d6.479397075468544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b892d819f59ff%3A0xe65d0586a0e9e9b1!2sTomez%20Ave%2C%20Amuwo%20Odofin%20Estate%2C%20Amuwo-Odofin%20Housing%20Estate%2C%20Mile%202%20102102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1720526489471!5m2!1sen!2sng" width="600" height="450" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Google Map'></iframe>
          </div>
          

          <div>
            <p className='whitespace-nowrap underline underline-offset-4 hover:underline-offset-8 duration-50000 text-white cursor-pointer py-5'
            onClick={()=> navigate('/contact')}
            >Contact Us</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer