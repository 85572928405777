import React from "react";
import Navbar from "../component/Navbar";
import AboutHero from "../assets/images/lagosmini.png"
import Footer from "../component/Footer";

function About() {
  return (
    <div>
      <div>
        <Navbar />
      </div>

      <section style={{ backgroundImage: `url(${AboutHero})` }} className="h-80 bg-cover bg-right sm:bg-center bg-no-repeat w-full align-middle my-auto justify-center flex flex-col sm:px-14 px-2 text-white">
        <h3 className="text-xl font-thin">Our Company</h3>
        <h1 className="text-5xl">Welcome to <br/>Opelope Oluwa Divine <br/>Transport Enterprise</h1>

      </section>

      <section>
        <div className="w-[90%] mx-auto flex flex-col sm:flex-row py-14">
          <div className="sm:w-[30%]">
            <h1 className="text-3xl text-[#595959]">Our History</h1> 
            <p className="text-lg text-[#003260]">Proven track record down the years</p>
            </div>
          <p className="sm:w-[70%] leading-7">Incorporated in 2021, Opelope Oluwa Transport began as a modest trucking business with a single truck, driven by our Founder's vision to transform Nigeria's transportation sector through reliability and efficiency. Our dedication paid off, and by 2024, we had expanded our fleet and services, covering key routes across the country with modern trucks and advanced logistics technology. We have consistently demonstrated our commitment to safety and customer satisfaction, transporting over 1 million tons of goods. Today, with a fleet of over 10 trucks, we stand as industry leaders, dedicated to superior logistics solutions, technological advancements, and positive community impact.</p>
        </div>

      </section>

      <section className="border-dotted border-t-[1px] border-[#0032608f]">
        <div className="w-[90%] mx-auto py-14 ">
          <h1 className="text-3xl text-[#595959]">Our Mission</h1> 
          <p className="leading-7">At OODT, our mission is to provide unparalleled container and goods trucking services across Nigeria, driven by our core values of reliability, safety, and efficiency. <br /> We strive to exceed customer expectations through innovative logistics solutions, advanced technology, and a dedicated team committed to excellence. Our goal is to ensure every shipment is handled with the utmost care and delivered on time, every time.
          <br /> We are dedicated to continuous improvement, sustainability, and adopting eco-friendly practices that reduce our environmental impact. <br />
          Ultimately, our mission is to lead the trucking industry in Nigeria, setting new standards for service quality, customer satisfaction, and corporate responsibility.
          </p>
        </div>

      </section>

      <section>
        <div className="w-[90%] mx-auto flex flex-col sm:flex-row py-14">
          <div className="sm:w-[30%]">
          <h1 className="text-3xl text-[#595959]">Our People</h1>
          <p className="text-lg text-[#003260]">Round the clock reliable and experienced team</p>

          </div>
          <p className="sm:w-[70%] leading-7">Our people are the heart and soul of our operations, driving our success and commitment to excellence. Our experienced drivers undergo continuous training programs, ensuring the highest standards of safety and efficiency. We take immense pride in our track record of zero accidents and no instances of missing customer goods, reflecting our unwavering commitment to reliability. Our logistics experts and customer service representatives work closely with our drivers to provide seamless services. We foster an inclusive and supportive work environment, promoting collaboration, innovation, and a shared dedication to exceeding customer expectations. Through their hard work and dedication, we continue to set new standards in the container and goods trucking industry, delivering exceptional service and building lasting relationships with our clients.</p>

        </div>

      </section>

      <Footer/>
    </div>
  );
}

export default About;
