import React from 'react'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
import { useNavigate } from 'react-router-dom';
import Service1 from '../assets/images/halCont.jpg'
import Service2 from '../assets/images/bulk.jpg'
import Service3 from '../assets/images/logis.jpg'
import Service4 from '../assets/images/ware.jpg'

function Services() {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar/>

      <div className='bg-[#003049] py-14 px-10'>
        <h1 className='text-5xl text-white font-semibold'>Explore Our Services</h1>
      </div>

      <section>
        <div className='flex flex-col sm:flex-row sm:py-10 py-5 border-dotted border-t-[1px] border-[#0032608f]'>
          <div className='flex flex-col sm:flex-col justify-center content-center sm:p-10 sm:w-[40%] px-2 '>
            <h1 className='text-3xl sm:text-5xl text-[#1c1c1c] font-bold capitalize sm:py-5'>CONTAINER HAULAGE</h1>
            <p className='text-slate-500'>Our container transport service is designed to facilitate the safe and efficient movement of containers to and from ports, warehouses, and other destinations across Nigeria. Whether you are shipping goods for domestic distribution or international export, our experienced team and modern fleet ensure seamless handling and timely delivery.</p>
            <div className='flex py-5' 
            onClick={() => {
              navigate("/get-a-quotation");
              // resetForm();
            }}
            >
            <button className='bg-[#1c1c1c] p-3 text-white '>Get a Quotation</button>

            </div>
          </div>
          <div className=' sm:w-[60%] '>
            <img className=' sm:h-[70vh] w-screen' src={Service1} alt="" />
          </div>
        </div>

        <div className='flex flex-col sm:flex-row sm:py-10 py-5 border-dotted border-t-[1px] border-[#0032608f]'>
        <div className=' sm:w-[60%] order-2 sm:order-1'>
            <img className='sm:h-[70vh] w-screen' src={Service2} alt="" />
          </div>

          <div className='flex flex-col sm:flex-col justify-center content-center sm:p-10 sm:w-[40%] px-2 sm:order-2 order-1' >
            <h1 className='text-3xl sm:text-5xl text-[#1c1c1c] font-bold capitalize sm:py-5'>BULK TRANSPORT</h1>
            <p className='text-slate-500'>We provide reliable goods trucking services to transport a wide range of products, from raw materials to finished goods, across Nigeria. Our team is equipped to handle various types of cargo, ensuring that each delivery is executed with precision and care.</p>
            <div className='flex py-5'
                        onClick={() => {
                          navigate("/get-a-quotation");
                          // resetForm();
                        }}
            >
            <button className='bg-[#1c1c1c] p-3 text-white'>Get a Quotation</button>

            </div>
          </div>

        </div>

        <div className='flex flex-col sm:flex-row sm:py-10 py-5 border-dotted border-t-[1px] border-[#0032608f]'>
          <div className='flex flex-col sm:flex-col justify-center content-center sm:p-10 sm:w-[40%] px-2 '>
            <h1 className='text-3xl sm:text-5xl text-[#1c1c1c] font-bold capitalize sm:py-5'>LOGISTICS MANAGEMENT</h1>
            <p className='text-slate-500'>Our logistics management service provides end-to-end solutions for your supply chain needs. From planning and coordination to execution and monitoring, we ensure that your logistics operations run smoothly and efficiently.</p>
            <div className='flex py-5'
                        onClick={() => {
                          navigate("/get-a-quotation");
                          // resetForm();
                        }}
            >
            <button className='bg-[#1c1c1c] p-3 text-white'>Get a Quotation</button>

            </div>
          </div>
          <div className=' sm:w-[60%] order-2 sm:order-1'>
            <img className='sm:h-[70vh] w-screen' src={Service3} alt="" />
          </div>
        </div>

        <div className='flex flex-col sm:flex-row sm:pt-10 pt-5 border-dotted border-t-[1px] border-[#0032608f]'>
        <div className=' sm:w-[60%] order-2 sm:order-1'>
            <img className='sm:h-[70vh] w-screen' src={Service4} alt="" />
          </div>

          <div className='flex flex-col sm:flex-col justify-center content-center sm:p-10 sm:w-[40%] px-2 sm:order-2 order-1'>
            <h1 className='text-3xl sm:text-5xl text-[#1c1c1c] font-bold capitalize sm:py-5'>WAREHOUSING</h1>
            <p className='text-slate-500'>Our warehousing solutions provide secure and efficient storage options for your goods. With strategically located facilities and advanced inventory management systems, we ensure that your products are stored safely and are easily accessible when needed.</p>
            <div className='flex py-5'
                        onClick={() => {
                          navigate("/get-a-quotation");
                          // resetForm();
                        }}
            >
            <button className='bg-[#1c1c1c] p-3 text-white'>Get a Quotation</button>

            </div>
          </div>

        </div>
      </section>


      <Footer/>
    </div>
  )
}

export default Services