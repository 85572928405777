import React, { useState } from 'react'
import Navbar from '../component/Navbar.jsx'
import Hero from '../assets/images/heroTruck.jpg'
import Service1 from '../assets/images/halCont.jpg'
import Service2 from '../assets/images/bulk.jpg'
import Service3 from '../assets/images/logis.jpg'
import Service4 from '../assets/images/ware.jpg'
import { Briefcase02Icon } from '@hugeicons/react-pro';
import HoverCard from '../component/HoverCard.jsx'
import { Icon1, Icon2, Icon3, Icon4 } from '../assets/icons/Svg.js';
import Icon from '../assets/icons/brief.svg'
import Bulk from '../assets/images/bulk.jpg'
import Footer from '../component/Footer.jsx';
import { useNavigate } from 'react-router-dom'



function Home() {
  const [hover, setHover] = useState(false)
  const navigate = useNavigate();

  return (
    <div className='bg-white h-screen font-Inter'>
      <Navbar/>

    <div style={{ backgroundImage: `url(${Hero})` }} title='Image by Tung Lam from Pixabay' className="h-[25rem] sm:h-[30rem] bg-cover bg-center bg-no-repeat w-full  flex ">
  <div className=" h-[25rem] sm:h-[30rem] w-full backdrop-brightness-200 bg-black/70 content-center items-center mx-auto text-white">
  <div className="sm:w-[60%] p-5 sm:p-10 flex-1">
    <h1 className="text-left  text-4xl lg:text-6xl md:text-4xl">
    Dependable container and Goods Trucking Services Across Nigeria.
    </h1>
    <div className='py-5'>
      <p>Efficient, Safe, and Reliable Transportation Solutions for Your Business Needs</p>
    </div>
    <div className='space-x-3'>
      <button className='bg-white hover:bg-black/100 text-black hover:text-white p-2'
      onClick={() => {
      navigate("/get-a-quotation");
      // resetForm();
      }}
      >Get a Quote</button>
      <button className='bg-white hover:bg-black/100 text-black hover:text-white p-2'
            onClick={() => {
              navigate("/contact");
              // resetForm();
              }}
      >Contact Us</button>
    </div>
    {/* <div className='flex text-white justify-between py-5 text-xl'>
      <p>Reliable</p>
      <p>On-time</p>
      <p>Affordable</p>
      <p>Experienced</p>
    </div> */}

  </div>

  </div>
</div>

<section>
  <div className='sm:w-[90%] px-2 py-10 sm:px-0 mx-auto grid sm:grid-cols-2 grid-cols-1 divide-x space-x-5'>
    <div className='flex flex-col align-middle my-auto mx-auto'>
      <h2 className='text-4xl leading-snug'> <span className='font-telma text-8xl pr-2'>Welcome</span> to <span className='bg-orange-300 rounded p-1 '>Opelope Oluwa Divine Transport Global Enterprise.</span> </h2>
      <p className='text-base py-5 text-slate-600'>Nigeria's emerging provider of comprehensive container and goods trucking services. With years of industry experience, a modern fleet, and a dedicated team, we ensure your cargo reaches its destination safely and on time. Explore our services or get in touch to see how we can meet your transportation needs.</p>
    </div>
    <div className=''>
      <img src={require('../assets/images/truck.jpg')} title='Image by freepik'/>
    </div>
  </div>
</section>

<section className='py-16'>
  <div className='flex flex-col mx-auto justify-center align-middle content-center text-center'>
    <div className='flex flex-col mx-auto justify-center'>
      <h1 className="text-orange-500 bg-orange-100 p-3 flex rounded-full font-semibold">Our Services</h1>
    </div>
    <div className='flex flex-col mx-auto py-5'>
      <h2 className='text-2xl sm:text-5xl mx-auto font-medium'>Solutions we provide across <br /> different aspect of transportation.</h2>
      <p className='mx-auto text-slate-600'>Discover the unparalleled benefits of our services and elevate your travel <br /> experience with us today!</p>
    </div>


      <div className='space-y-10  sm:px-10 '>
        <div className='grid grid-cols-1 sm:grid-cols-2 sm:mx-auto space-y-10 sm:space-y-0 sm:space-x-10'>
            <HoverCard
                      image={Service1}
                      title="Container Haulage"
                      description="Our container transport service is designed to facilitate the safe and efficient movement of containers to and from ports, warehouses, and other destinations across Nigeria."
                      iconName="icon1"
                      bg="bg-purple-500" 
                      iconBg="bg-purple-400"
              />


        <HoverCard
                  image={Service2}
                  title="Bulk Transport"
                  description="We provide reliable goods trucking services to transport a wide range of products, from raw materials to finished goods, across Nigeria"
                  iconName="icon2"
                  bg="bg-green-500" 
                  iconBg="bg-green-400"
          />
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 sm:mx-auto space-y-10 sm:space-y-0 sm:space-x-10'>
        <HoverCard
                  image={Service3}
                  title="Logistics Management"
                  description="Our logistics management service provides end-to-end solutions for your supply chain needs."
                  iconName="icon3"
                  bg="bg-orange-500" 
                  iconBg="bg-orange-400"
          />

        <HoverCard
                  image={Service4}
                  title="Warehousing"
                  description="Our warehousing solutions provide secure and efficient storage options for your goods."
                  iconName="icon4"
                  bg="bg-red-500" 
                  iconBg="bg-red-400"
          />
        </div>


      </div>
  </div>
</section>


{/* <section>
  <h1>Connect with US</h1>
</section> */}

<Footer/>
    </div>
  )
}

export default Home

{/* <div>
<div 
onMouseEnter={()=> setHover(true)}
onMouseLeave={()=> setHover(false)}
style={{ backgroundImage: `url(${Service1})` }} className='h-96 sm:h-96 w-96 bg-cover bg-center bg-no-repeat  relative flex m-3  rounded-t-2xl ' >        

  <div className={`text-left bg-white absolute rounded-t-2xl transition-all duration-700 ease-in-out transform flex flex-col justify-between p-2 ${hover ? "bg-purple-500 bottom-0 top-0 p-2 transition-all duration-500 ease-in-out transform":"-bottom-10 top-80 -translate-y-10"}`}>

  <div>
    {hover && <div className='flex flex-col w-fit p-2 bg-purple-400 rounded-lg justify-start transition-all duration-700 ease-in-out transform'>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={40} height={40} color={"#ffffff"} fill={"none"}>
<path d="M2.57956 8.62505C2.50886 8.03528 2.47351 7.74039 2.52323 7.499C2.6651 6.81015 3.27111 6.25159 4.07871 6.06529C4.36172 6 4.717 6 5.42757 6H18.5724C19.283 6 19.6383 6 19.9213 6.06529C20.7289 6.25159 21.3349 6.81015 21.4768 7.499C21.5265 7.74039 21.4911 8.03528 21.4204 8.62505C21.2584 9.97669 20.4991 10.716 19.0512 11.1423L14.88 12.3703C13.4541 12.7901 12.7411 13 12 13C11.2589 13 10.5459 12.7901 9.11996 12.3703L4.94882 11.1423C3.50094 10.7161 2.7416 9.97669 2.57956 8.62505Z" stroke="currentColor" strokeWidth="1.5" />
<path d="M3.46283 10.5L3.26658 12.7757C2.91481 16.855 2.73892 18.8947 3.86734 20.1974C4.99576 21.5 6.93851 21.5 10.824 21.5H13.176C17.0615 21.5 19.0042 21.5 20.1327 20.1974C21.2611 18.8947 21.0852 16.855 20.7334 12.7757L20.5372 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M15.5 5.5L15.4227 5.23509C15.0377 3.91505 14.8452 3.25503 14.3869 2.87752C13.9286 2.5 13.3199 2.5 12.1023 2.5H11.8977C10.6801 2.5 10.0714 2.5 9.61309 2.87752C9.15478 3.25503 8.96228 3.91505 8.57727 5.23509L8.5 5.5" stroke="currentColor" strokeWidth="1.5" />
    </svg>
    </div>}

    <h1 className={`py-3 text-3xl font-semibold ${hover ? "text-white transition-all duration-700 ease-in-out transform" :""} `}>Haulage</h1>
  <p className={`${hover ? "text-white transition-all duration-700 ease-in-out transform" : "text-slate-500 line-clamp-2"} text-xl leading-6`}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores, reiciendis! Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>

  </div>


    <div className='flex'>
    {hover && <button className='flex bg-white rounded-lg p-3 text-slate-600'>Learn More</button>}

    </div>

  </div>

</div>

</div> */}